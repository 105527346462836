@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full font-blitz-sans;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .title {
    @apply max-lg:title--large lg:title--normal;
  }

  .title--large {
    @apply title--base text-3xl sm:text-4xl lg:text-6xl;
  }

  .title--normal {
    @apply title--base text-2xl sm:text-3xl lg:text-[40px] lg:leading-[1.2];
  }

  .title--small {
    @apply title--base text-[24px];
  }

  .title--rotate {
    @apply title--large lg:title--normal text-white mb-6 lg:mb-0 text-opacity-75 lg:text-[120px] lg:opacity-[0.15] lg:-rotate-90 lg:absolute lg:top-[22rem] xl:top-[27rem] lg:-left-72;
  }

  .btn {
    @apply btn--base btn--pseudo bg-blitz-nougat text-white hover:bg-blitz-shark;
  }

  .btn--alt {
    @apply btn--base btn--pseudo bg-blitz-shark text-white hover:bg-transparent hover:border-blitz-shark hover:text-blitz-shark;
  }

  .section {
    @apply py-12 lg:py-20;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }
}

@layer utilities {
  .title--base {
    @apply font-bold text-blitz-shark uppercase font-blitz-futura;
  }

  .btn--pseudo {
    @apply transition-all disabled:opacity-75;
  }

  .btn--base {
    @apply py-3 border border-transparent px-[22px] uppercase rounded-full font-bold inline-flex font-blitz-futura;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-blitz-nougat;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold;
  }

  .content--paragraphs {
    @apply max-lg:children-p:text-lg children-p:leading-[26px] children-p:mb-4 children-p:font-light children-p:text-black children-p:tracking-[0.26px];
  }

  .content--headings {
    @apply children-headings:mb-4 children-h1:mb-5 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl children-headings:font-bold children-headings:text-blitz-shark/75;
  }

  .triangle {
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    background-color: #ffffff;
  }

  .triangle--black {
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    background-color: #222323;
  }

  .bg-shadow {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.85) 89.46%);
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-blitz-nougat h-3 rounded-r-full;
}

#nprogress .spinner-icon {
  @apply hidden;
}

/* #nprogress .peg {
  @apply shadow;
} */

/* Form helpers */

.form-duuf-submit > button {
  @apply btn--alt;
}

.form-duuf-group {
  @apply mb-5;
}

.form-duuf-group-submit {
  @apply pt-5 flex justify-center;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-white/50 flex-center;
}

.form-duuf-group-name .form-duuf-group {
  @apply sm:w-1/2;
}

.form-duuf-group-name .form-duuf-group .form-duuf-label {
  @apply hidden;
}

.form-duuf-label {
  @apply block;
}

.form-duuf-error {
  @apply px-5 font-medium text-red-600 text-sm;
}

.form-duuf-checkbox-label {
  @apply ml-2 font-light;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply border-black text-blitz-shark bg-white border-none p-5 w-full;
}

.form-duuf-input {
  @apply rounded-full h-[50px];
}

.form-duuf-textarea {
  @apply rounded-3xl h-[150px] sm:h-[200px] resize-none;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--alt py-1 px-2;
}

/* Custom */
